.avatar {
  max-width: 250px;
  img {
    width: 100%;
    border-radius: 50%;
  }
}
@media screen and (max-width: 992px) {
  .avatar {
    max-width: 100px;
  }
}